import {RolesEnum} from './roles.enum';

export class AuthRoleModel {
  userId: string;
  fullName: string;
  username: string;
  phoneNo: string;
  createdDate: string;
  enabled: boolean;
  roleName: string;
  roleNo: RolesEnum;
  studentId: string;
  teacherId: string;

  constructor(dto: any) {
    if (dto.userId) {
      this.userId = dto.userId;
    }
    if (dto.fullName) {
      this.fullName = dto.fullName;
    }
    if (dto.username) {
      this.username = dto.username;
    }
    if (dto.phoneNo) {
      this.phoneNo = dto.phoneNo;
    }
    if (dto.createdDate) {
      this.createdDate = dto.createdDate;
    }
    if (dto.enabled) {
      this.enabled = dto.enabled;
    }
    if (dto.roleName) {
      this.roleName = dto.roleName;
    }
    if (dto.roleNo) {
      this.roleNo = dto.roleNo;
    }
    if (dto.studentId) {
      this.studentId = dto.studentId;
    }
    if (dto.teacherId) {
      this.teacherId = dto.teacherId;
    }
  }
}
