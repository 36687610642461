import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {setUser} from '@sentry/angular';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {ServiceResponseType} from '../../types/service-response.type';
import {AuthLoginModel, AuthLoginType} from './auth-login.model';
import {AuthRoleModel} from './auth-role.model';
import {AuthUserDetailModel} from './auth-user-detail.model';
import {ChangePasswordRequestModel} from './change-password-request.model';
import {RolesEnum} from './roles.enum';
import {UpdateProfileRequestModel} from './update-profile-request.model';

@Injectable()
export class AuthService {
  readonly #authSubject: BehaviorSubject<AuthLoginModel | null> = new BehaviorSubject<AuthLoginModel | null>(
    localStorage.getItem('xp:auth')
      ? new AuthLoginModel().fromLocalStorage(JSON.parse(localStorage.getItem('xp:auth')))
      : null,
  );

  readonly #roleSubject: BehaviorSubject<AuthRoleModel | null> = new BehaviorSubject<AuthRoleModel | null>(
    localStorage.getItem('xp:role') ? new AuthRoleModel(JSON.parse(localStorage.getItem('xp:role'))) : null,
  );

  readonly #userSubject: BehaviorSubject<AuthUserDetailModel | null> = new BehaviorSubject<AuthUserDetailModel | null>(
    localStorage.getItem('xp:user') ? new AuthUserDetailModel(JSON.parse(localStorage.getItem('xp:user'))) : null,
  );

  constructor(
    private readonly router: Router,
    private readonly http: HttpClient,
  ) {}

  isLogin(): boolean {
    return !!this.auth && !!this.role && !!this.user;
  }

  set auth(value: AuthLoginModel | null) {
    if (value) {
      localStorage.setItem('xp:auth', JSON.stringify(value));
    } else {
      localStorage.removeItem('xp:auth');
    }
    this.#authSubject.next(value);
  }

  get auth(): AuthLoginModel | null {
    return this.#authSubject.value;
  }

  set role(value: AuthRoleModel | null) {
    if (value) {
      localStorage.setItem('xp:role', JSON.stringify(value));
    } else {
      localStorage.removeItem('xp:role');
    }
    this.#roleSubject.next(value);
  }

  get role(): AuthRoleModel | null {
    return this.#roleSubject.value;
  }

  set user(value: AuthUserDetailModel | null) {
    if (value) {
      localStorage.setItem('xp:user', JSON.stringify(value));
    } else {
      localStorage.removeItem('xp:user');
    }
    this.#userSubject.next(value);
  }

  get user(): AuthUserDetailModel | null {
    return this.#userSubject.value;
  }

  findRoleName(): 'teacher' | 'student' {
    switch (this.role.roleNo) {
      case RolesEnum.TEACHER:
        return 'teacher';
      case RolesEnum.STUDENT:
        return 'student';
      default:
        return null;
    }
  }

  signIn(request: {username: string; password: string}): Observable<AuthRoleModel> {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const body = new URLSearchParams({
      ...request,
      grant_type: 'password',
    });

    return this.http.post<AuthLoginType>('/token', body.toString(), {headers}).pipe(
      map(response => {
        const authLoginModel: AuthLoginModel = new AuthLoginModel().convert(response);
        this.auth = authLoginModel;

        return authLoginModel;
      }),
      switchMap(model =>
        this.getRoleByUsername(model.username).pipe(
          switchMap(response => {
            if ([RolesEnum.STUDENT, RolesEnum.TEACHER].some(each => response.roleNo === each)) {
              this.role = response;
              setUser({email: request.username});
              return of(response);
            } else {
              this.auth = null;
              return throwError(() => 'current user role is not authorized');
            }
          }),
        ),
      ),
    );
  }

  refreshToken(refresh_token: string): Observable<unknown> {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const body = new URLSearchParams({
      refresh_token,
      grant_type: 'refresh_token',
    });

    return this.http.post<AuthLoginType>('/token', body.toString(), {headers}).pipe(
      map(response => {
        const authLoginModel: AuthLoginModel = new AuthLoginModel().convert(response);
        this.auth = authLoginModel;

        return authLoginModel;
      }),
    );
  }

  signOut(): Promise<boolean> {
    this.auth = null;
    this.role = null;
    this.user = null;
    setUser(null);
    return this.router.navigateByUrl('/sign-in', {replaceUrl: true});
  }

  getRoleByUsername(username: string): Observable<AuthRoleModel> {
    return this.http.get<AuthRoleModel>(`/user/detail/${username}`).pipe(map(response => new AuthRoleModel(response)));
  }

  getUserDetail(detailId: string, role?: 'student' | 'teacher'): Observable<AuthUserDetailModel> {
    const roleName = role || this.findRoleName();
    return this.http
      .get<AuthUserDetailModel>(`/${roleName}/detail/${detailId}`)
      .pipe(map(response => new AuthUserDetailModel(response)));
  }

  updateProfile(body: UpdateProfileRequestModel): Observable<ServiceResponseType> {
    return this.http.post<ServiceResponseType>('/user/update-front', body.convert());
  }

  changePassword(body: ChangePasswordRequestModel): Observable<ServiceResponseType> {
    return this.http.post<ServiceResponseType>('/user/changePassword', body.convert());
  }
}
