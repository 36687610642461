export class AuthUserDetailModel {
  basedPrice: number = 0;
  bio: string = '';
  birthDate: string | Date;
  birthPlace: string = '';
  education: string = '';
  identificationNo: string = '';
  interest: string = '';
  parentAddress: string = '';
  parentIdentificationNumber: string = '';
  parentName: string = '';
  parentPhoneNumber: string = '';
  parentResidenceAddress: string = '';
  personality: string = '';
  phoneNumber: string = '';
  photo: string = '';
  schoolName: string = '';
  semester: string = '';
  studentId: string = '';
  studentName: string = '';
  studentNo: string = '';
  typeStudentCode: string = '';
  typeStudentId: string = '';
  typeStudentName: string = '';
  userId: string = '';
  username: string = '';
  address: string = '';
  bachelorMajors: string = '';
  bachelorUniversityName: string = '';
  createdDate: string | Date;
  doctorMajors: string = '';
  doctorUniversityName: string = '';
  elementarySchoolName: string = '';
  highSchoolMajor: string = '';
  highSchoolName: string = '';
  lastEducation: string = '';
  masterMajors: string = '';
  masterUniversityName: string = '';
  middleSchoolName: string = '';
  residenceAddress: string = '';
  salary: number = 0;
  teacherId: string = '';
  teacherName: string = '';
  teacherNo: string = '';
  typeTeacherCode: string = '';
  typeTeacherId: string = '';
  typeTeacherName: string = '';
  age: number = 0;
  latitude: string = '';
  longitude: string = '';
  teacherIds: string[];
  updatedBy: string = '';
  imageUrl: string[];
  subjectIds: string[];
  typeName: string = '';

  constructor(dto: any) {
    if (dto.basedPrice) {
      this.basedPrice = dto.basedPrice;
    }
    if (dto.bio) {
      this.bio = dto.bio;
    }
    if (dto.birthDate) {
      this.birthDate = dto.birthDate;
    }
    if (dto.birthPlace) {
      this.birthPlace = dto.birthPlace;
    }
    if (dto.education) {
      this.education = dto.education;
    }
    if (dto.identificationNo) {
      this.identificationNo = dto.identificationNo;
    }
    if (dto.interest) {
      this.interest = dto.interest;
    }
    if (dto.parentAddress) {
      this.parentAddress = dto.parentAddress;
    }
    if (dto.parentIdentificationNumber) {
      this.parentIdentificationNumber = dto.parentIdentificationNumber;
    }
    if (dto.parentName) {
      this.parentName = dto.parentName;
    }
    if (dto.parentPhoneNumber) {
      this.parentPhoneNumber = dto.parentPhoneNumber;
    }
    if (dto.parentResidenceAddress) {
      this.parentResidenceAddress = dto.parentResidenceAddress;
    }
    if (dto.personality) {
      this.personality = dto.personality;
    }
    if (dto.phoneNumber) {
      this.phoneNumber = dto.phoneNumber;
    }
    if (dto.photo) {
      this.photo = dto.photo;
    }
    if (dto.schoolName) {
      this.schoolName = dto.schoolName;
    }
    if (dto.semester) {
      this.semester = dto.semester;
    }
    if (dto.studentId) {
      this.studentId = dto.studentId;
    }
    if (dto.studentName) {
      this.studentName = dto.studentName;
    }
    if (dto.studentNo) {
      this.studentNo = dto.studentNo;
    }
    if (dto.typeStudentCode) {
      this.typeStudentCode = dto.typeStudentCode;
    }
    if (dto.typeStudentId) {
      this.typeStudentId = dto.typeStudentId;
    }
    if (dto.typeStudentName) {
      this.typeStudentName = dto.typeStudentName;
    }
    if (dto.userId) {
      this.userId = dto.userId;
    }
    if (dto.username) {
      this.username = dto.username;
    }
    if (dto.address) {
      this.address = dto.address;
    }
    if (dto.bachelorMajors) {
      this.bachelorMajors = dto.bachelorMajors;
    }
    if (dto.bachelorUniversityName) {
      this.bachelorUniversityName = dto.bachelorUniversityName;
    }
    if (dto.createdDate) {
      this.createdDate = dto.createdDate;
    }
    if (dto.doctorMajors) {
      this.doctorMajors = dto.doctorMajors;
    }
    if (dto.doctorUniversityName) {
      this.doctorUniversityName = dto.doctorUniversityName;
    }
    if (dto.elementarySchoolName) {
      this.elementarySchoolName = dto.elementarySchoolName;
    }
    if (dto.highSchoolMajor) {
      this.highSchoolMajor = dto.highSchoolMajor;
    }
    if (dto.highSchoolName) {
      this.highSchoolName = dto.highSchoolName;
    }
    if (dto.lastEducation) {
      this.lastEducation = dto.lastEducation;
    }
    if (dto.masterMajors) {
      this.masterMajors = dto.masterMajors;
    }
    if (dto.masterUniversityName) {
      this.masterUniversityName = dto.masterUniversityName;
    }
    if (dto.middleSchoolName) {
      this.middleSchoolName = dto.middleSchoolName;
    }
    if (dto.residenceAddress) {
      this.residenceAddress = dto.residenceAddress;
    }
    if (dto.salary) {
      this.salary = dto.salary;
    }
    if (dto.teacherId) {
      this.teacherId = dto.teacherId;
    }
    if (dto.teacherName) {
      this.teacherName = dto.teacherName;
    }
    if (dto.teacherNo) {
      this.teacherNo = dto.teacherNo;
    }
    if (dto.typeTeacherCode) {
      this.typeTeacherCode = dto.typeTeacherCode;
    }
    if (dto.typeTeacherId) {
      this.typeTeacherId = dto.typeTeacherId;
    }
    if (dto.typeTeacherName) {
      this.typeTeacherName = dto.typeTeacherName;
    }
    if (dto.age) {
      this.age = dto.age;
    }
    if (dto.latitude) {
      this.latitude = dto.latitude;
    }
    if (dto.longitude) {
      this.longitude = dto.longitude;
    }
    if (dto.teacherIds) {
      this.teacherIds = dto.teacherIds;
    }
    if (dto.updatedBy) {
      this.updatedBy = dto.updatedBy;
    }
    if (dto.imageUrl) {
      this.imageUrl = dto.imageUrl;
    }
    if (dto.subjectIds) {
      this.subjectIds = dto.subjectIds;
    }
    if (dto.typeName) {
      this.typeName = dto.typeName;
    }
  }

  convert(): Record<string, any> {
    return {
      basedPrice: this.basedPrice,
      bio: this.bio,
      birthDate: this.birthDate,
      birthPlace: this.birthPlace,
      education: this.education,
      identificationNo: this.identificationNo,
      interest: this.interest,
      parentAddress: this.parentAddress,
      parentIdentificationNumber: this.parentIdentificationNumber,
      parentName: this.parentName,
      parentPhoneNumber: this.parentPhoneNumber,
      parentResidenceAddress: this.parentResidenceAddress,
      personality: this.personality,
      phoneNumber: this.phoneNumber,
      photo: this.photo,
      schoolName: this.schoolName,
      semester: this.semester,
      studentId: this.studentId,
      studentName: this.studentName,
      studentNo: this.studentNo,
      typeStudentCode: this.typeStudentCode,
      typeStudentId: this.typeStudentId,
      typeStudentName: this.typeStudentName,
      userId: this.userId,
      username: this.username,
      address: this.address,
      bachelorMajors: this.bachelorMajors,
      bachelorUniversityName: this.bachelorUniversityName,
      createdDate: this.createdDate,
      doctorMajors: this.doctorMajors,
      doctorUniversityName: this.doctorUniversityName,
      elementarySchoolName: this.elementarySchoolName,
      highSchoolMajor: this.highSchoolMajor,
      highSchoolName: this.highSchoolName,
      lastEducation: this.lastEducation,
      masterMajors: this.masterMajors,
      masterUniversityName: this.masterUniversityName,
      middleSchoolName: this.middleSchoolName,
      residenceAddress: this.residenceAddress,
      salary: this.salary,
      teacherId: this.teacherId,
      teacherName: this.teacherName,
      teacherNo: this.teacherNo,
      typeTeacherCode: this.typeTeacherCode,
      typeTeacherId: this.typeTeacherId,
      typeTeacherName: this.typeTeacherName,
      age: this.age,
      latitude: this.latitude,
      longitude: this.longitude,
      teacherIds: this.teacherIds,
      updatedBy: this.updatedBy,
      imageUrl: this.imageUrl,
      subjectIds: this.subjectIds,
      typeName: this.typeName,
    };
  }
}
